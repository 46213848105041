import React from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import { intlFlattenTexts } from './shared/utils/intl-locale';

const intlCache = createIntlCache();

const loadMessagesInLocale = async locale => {
  const messages = await import(`./locales/${locale}.json`);
  return intlFlattenTexts(messages.default);
};

const defaultLocale = 'fr';
let defaultMessages;
const initDefaultMessages = async () => {
  defaultMessages = await loadMessagesInLocale(defaultLocale);
};
initDefaultMessages();
const defaultRichTextElements = {
  br: <br />,
  div: chunks => <div>{chunks}</div>,
  p: chunks => <p>{chunks}</p>,
  strong: chunks => <strong>{chunks}</strong>,
};

export let intl = createIntl(
  {
    defaultLocale,
    defaultRichTextElements,
    locale: defaultLocale,
    messages: defaultMessages,
  },
  intlCache,
);

export const updateIntl = async locale => {
  const messages = await loadMessagesInLocale(locale);
  intl = createIntl(
    {
      defaultLocale,
      defaultRichTextElements,
      locale,
      messages,
    },
    intlCache,
  );
  return intl;
};

// for test only
export const resetIntl = () => {
  intl = createIntl(
    {
      defaultLocale,
      defaultRichTextElements,
      locale: defaultLocale,
      messages: {},
    },
    intlCache,
  );
};
