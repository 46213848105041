import * as userConst from '../actions/const/user.const';
import * as loginConst from '../../pages/login/actions/login.const';
import * as commonConst from '../actions/const/common.const';
import { possibleAccountStatus } from '../utils/user';
import { utils } from '@utocat/catalizr-const-sharing-lib';

const initState = {
  // email of the user (potentialy an investor or a company)
  email: '',
  id: -1,
  status: possibleAccountStatus.NOT_CHECKED,
  level: '',
  firstname: '',
  lastname: '',
  title: '',
  phone: '',
  type: '',
  logged: false,
  getUserInfoLoading: false,
  bossUserCompanies: [],
  company_name: '', // specific for corporation investor
  siren: '', // specific for corporation investor
  theme: 'default',
  locale: 'fr',
};

const user = (state = initState, action) => {
  switch (action.type) {
    case loginConst.INIT_VIEW:
    case commonConst.INIT_STATE:
    case commonConst.CLEAR_LOGOUT:
    case commonConst.CLEAR_LOGOUT_PENDING:
      return initState;

    // set email and keep data on store : user is logged
    case userConst.SET_USER_EMAIL:
      return { ...state, email: action.email.toLowerCase() };

    // set email and empty data on store : user is not logged
    case userConst.SET_USER_EMAIL_LOGIN:
      return { ...initState, email: action.email.toLowerCase() };

    case userConst.GET_USER_INFOS_FULFILLED: {
      // fix temporaire, à revoir pendant la refacto
      let userData = action.user ? action.user : action.payload.data.data;
      return {
        ...state,
        email: userData.email,
        user_id: userData.sql_id,
        id: userData.utorize_id,
        firstname: userData.first_name,
        lastname: userData.last_name,
        type: userData.role,
        logged: true,
        getUserInfoLoading: false,
        updatedAtDisplay: userData.updatedAtDisplay,
        phone: userData.phone,
        title: userData.title,
        bossUserCompanies: userData.bossUserCompanies,
        userCompanies: userData.userCompanies,
        company_name: userData.company_name,
        siren: userData.siren,
      };
    }

    case userConst.GET_USER_INFOS_PENDING:
      return {
        ...state,
        getUserInfoLoading: true,
      };

    case userConst.USER_LOGOUT_FULFILLED:
      return {
        ...state,
        logged: false,
      };

    case userConst.SET_USER_DATA:
      return {
        ...state,
        firstname: action.user.first_name,
        lastname: action.user.last_name,
        title: action.user.title,
        phone: action.user.phone,
        level: action.user.level,
        updatedAtDisplay: action.user.updatedAtDisplay,
      };
    case loginConst.RESET_PASSWORD_REJECTED:
      return { ...state, status: possibleAccountStatus.DOES_NOT_EXIST };

    case loginConst.RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        status: possibleAccountStatus.NOT_CHECKED,
      };

    case userConst.UPDATE_USER_DATA: {
      return {
        ...state,
        ...action.userData,
      };
    }

    case userConst.ADD_THEME_TO_USER: {
      if (action.payload) {
        if (utils.users.isBankRole(state.type) || utils.users.isInvestorRole(state.type)) {
          return {
            ...state,
            theme: action.payload,
          };
        }
        return {
          ...state,
          theme: 'default',
        };
      }
      return {
        ...state,
      };
    }

    case userConst.SET_USER_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }

    default:
      return state;
  }
};

export default user;
