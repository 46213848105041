import React from 'react';
import { utils } from '@utocat/catalizr-const-sharing-lib';

import { intl } from '../../intlConfig';
import CustomHeader from '../../components/Table/components/Headers/CustomHeader';

export const addOptionalMention = (labelOrMessage, params = {}) => {
  if (typeof labelOrMessage === 'string') {
    return intl.formatMessage(
      {
        id: 'form.optional_%field',
        defaultMessage: '{field} (optional)',
      },
      {
        field: labelOrMessage,
      },
    );
  }
  if (typeof labelOrMessage === 'object') {
    return intl.formatMessage(
      {
        id: 'form.optional_%field',
        defaultMessage: '{field} (optional)',
      },
      {
        field: intl.formatMessage(labelOrMessage, params),
      },
    );
  }
  // don't known what is it, so return as it
  return labelOrMessage;
};

export const translatePortfolioType = ptfType => {
  if (typeof ptfType === 'string' && ptfType) {
    return intl.formatMessage({
      id: utils.translation.getPortfolioTypeKey(ptfType),
    });
  }
  return ptfType;
};

export const translateOperationType = opType => {
  if (typeof opType !== 'string' || opType === '') {
    return opType;
  }
  const tradKey = utils.translation.getOperationTypeKey(opType);
  if (tradKey !== opType) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return opType;
};

export const translateOperationTypeTooltip = (opType, description) => {
  if (typeof opType !== 'string' || opType === '') {
    return opType;
  }
  const tradKey = utils.translation.getOperationTypeKey(opType);
  if (tradKey !== opType) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: `${tradKey}_TOOLTIP`,
    });
  }
  return description;
};

export const translateShareholdingMovementType = mvtType => {
  if (typeof mvtType !== 'string' || mvtType === '') {
    return mvtType;
  }
  const tradKey = utils.translation.getShareholdingMovementTypeKey(mvtType);
  if (tradKey !== mvtType) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return mvtType;
};

export const translateUserRole = userRole => {
  if (typeof userRole !== 'string' || userRole === '') {
    return userRole;
  }
  let tradKey = utils.translation.getUserRoleKey(userRole);
  if (tradKey === userRole) {
    tradKey = utils.translation.getUserRoleKey(userRole.toLowerCase());
  }
  if (tradKey !== userRole) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  return userRole;
};

export const translateEmailStatus = emailStatus => {
  if (typeof emailStatus !== 'string' || emailStatus === '') {
    return emailStatus;
  }
  const tradKey = utils.translation.getEmailStatusKey(emailStatus);
  if (tradKey !== emailStatus) {
    // verify of trad key has been converted, else not an operation type
    return intl.formatMessage({
      id: tradKey,
    });
  }
  if (emailStatus === 'none') {
    return intl.formatMessage({
      id: 'enum.email.status.none',
    });
  }
  return emailStatus;
};

export const getTableHeaderContent = (column, customSort = false) => {
  let cellContent = column.header;
  if (typeof column.header === 'string') {
    cellContent = intl.formatMessage({
      id: `table.columns.${column.header}`,
    });
  } else if (typeof column.header === 'function') {
    cellContent = column.header(intl);
  }
  if (customSort) {
    let tooltip;
    if (column.tooltip) {
      tooltip = intl.formatMessage({
        id: `tooltip.${column.tooltip}`,
      });
    }
    return options => {
      let sortBy = {
        id: '',
        desc: false,
      };
      if (options.state && Array.isArray(options.state.sortBy) && options.state.sortBy.length > 0) {
        sortBy = options.state.sortBy[0];
      }
      return (
        <CustomHeader
          sorted={sortBy.id === options.column.id}
          sortDesc={sortBy.desc}
          header={cellContent}
          tooltip={tooltip}
        />
      );
    };
  }
  return cellContent;
};
