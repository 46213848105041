import { Enum } from '@utocat/catalizr-const-sharing-lib';

export const NAME_SURNAME_REGEX =
  "[0-9A-Za-zĄÀÁÂÃÄÅÇĆÈÉÊËĘÌÍÎÏŁÑŃÒÓÔÕÖŚÙÚÛÜÝŹŻąàáâãäåæçćèéêëęìíîïłñńòóôõöśùúûüýÿźżß`\\'()\\-\\s]";

export const FAKE_IBAN_PATTERN = 'FAKE_GENERATED_IBAN_';
export const TITLE_CORPORATION = 'Personne morale';

// --------------------------------------------------------------------------------------------
// List of operation types allowed
// --------------------------------------------------------------------------------------------

export const OPERATION_TYPE_CREATE = Enum.Operations.Type.CREATE;
export const OPERATION_TYPE_CAPITAL_INCREASE = Enum.Operations.Type.INCREASE;
export const OPERATION_TYPE_REPURCHASE = Enum.Operations.Type.REPURCHASE;
export const OPERATION_TYPE_DISPOSAL = Enum.Operations.Type.DISPOSAL;
export const OPERATION_TYPE_BOND = Enum.Operations.Type.BOND;
export const OPERATION_TYPE_PRIVATE_EQUITY = Enum.Operations.Type.PRIVATE_EQUITY;
export const OPERATION_TYPE_KIND_CONTRIBUTION = Enum.Operations.Type.KIND_CONTRIBUTION;

// --------------------------------------------------------------------------------------------
// List of operation status : sort by logic order
// --------------------------------------------------------------------------------------------
export const OPERATION_STATUSES = {
  WAITING_DOCUMENT: Enum.Operations.Status.WAITING_DOCUMENT, // 20%
  CO_INVESTOR_SIGNED: Enum.Operations.Status.CO_INVESTOR_SIGNED, // 30%
  INVESTOR_SIGNED: Enum.Operations.Status.INVESTOR_SIGNED, // 40%
  ALL_SIGNED: Enum.Operations.Status.ALL_SIGNED, // 60%
  PAYMENT_TO_BE_MADE: Enum.Operations.Status.PAYMENT_TO_BE_MADE, // 60%
  PAYMENT_EMITTED: Enum.Operations.Status.PAYMENT_EMITTED, // 60%
  PAYMENT_RECEIVED: Enum.Operations.Status.PAYMENT_RECEIVED, // 60%
  INVESTMENT_UPDATED: Enum.Operations.Status.INVESTMENT_UPDATED, // 95%
  CANCELLED: Enum.Operations.Status.CANCELLED, // 0%
  INVESTMENT_CLOSED: Enum.Operations.Status.INVESTMENT_CLOSED, // 100%
};

// --------------------------------------------------------------------------------------------
// List of users
// --------------------------------------------------------------------------------------------
export const USER_TYPE = {
  BANK: 'Banque',
};

// --------------------------------------------------------------------------------------------
// List of creation method
// --------------------------------------------------------------------------------------------
export const CREATION_METHOD = {
  COMPLETE: 'COMPLETE',
  TRANSMIT: 'TRANSMIT',
};

// --------------------------------------------------------------------------------------------
// Tooltip associated to the operation status
// --------------------------------------------------------------------------------------------

export const TIMELINE_STATUS = {
  STEP1: "Documents à fournir et signature de la lettre d'engagement",
  STEP1_CO_INVESTOR_2: 'Confirmation des informations et signature des documents',
  STEP1_PRIVATE_EQUITY: 'Documents à fournir et signature des documents de souscription',
  STEP1_DISPOSAL: 'Fourniture du dossier de cession',
  STEP2: "Documents à transmettre et accusé de réception de la lettre d'intention",
  STEP2_PRIVATE_EQUITY: 'Validation de la souscription',
  STEP3_DISPOSAL: 'Rapprochement du virement',
  STEP3: 'Validation et transfert des fonds',
  STEP4: 'Réception des fonds',
  STEP4_REPURCHASE: 'Confirmation de la vente des titres',
  STEP5: 'Génération et signature de l’attestation de propriété',
  STEP6: 'Contrôle réglementaire et enregistrement des titres',
  STEP6_DISPOSAL: 'Contrôle réglementaire et dés-enregistrement des titres',
  FINAL_STEP: '',
};

// --------------------------------------------------------------------------------------------
// Objects containing the data needed to display the dashboard timeline
// --------------------------------------------------------------------------------------------

export const SIGN_DOCUMENTS_ENTITY_TYPE = {
  investor: 'INVESTOR',
  company: 'COMPANY',
  advisor: 'INVESTOR',
  backoffice: 'INVESTOR',
};

export const PROGRESS_BAR_STATUS = {
  [Enum.Operations.Status.WAITING_DOCUMENT]: 'components.progressbar.operation.pending',
  [Enum.Operations.Status.CO_INVESTOR_SIGNED]: 'components.progressbar.operation.first_signature',
  [Enum.Operations.Status.INVESTOR_SIGNED]: 'components.progressbar.operation.le_signed',
  [Enum.Operations.Status.ALL_SIGNED]: 'components.progressbar.operation.all_signed',
  [Enum.Operations.Status.PAYMENT_EMITTED]: 'components.progressbar.operation.payment_emitted',
  [Enum.Operations.Status.PAYMENT_RECEIVED]: 'components.progressbar.operation.payment_received',
  [Enum.Operations.Status.INVESTMENT_UPDATED]: 'components.progressbar.operation.closed',
};

export const PROGRESS_BAR_STATUS_DISPOSAL_OPERATION = {
  [Enum.Operations.Status.WAITING_DOCUMENT]: 'components.progressbar.operation.pending',
  [Enum.Operations.Status.CO_INVESTOR_SIGNED]: 'components.progressbar.operation.first_signature',
  [Enum.Operations.Status.INVESTMENT_UPDATED]: 'components.progressbar.operation.closed',
};

export const PROGRESS_BAR_STATUS_PRIVATE_EQUITY_OPERATION = {
  [Enum.Operations.Status.WAITING_DOCUMENT]: 'components.progressbar.operation.pending',
  [Enum.Operations.Status.CO_INVESTOR_SIGNED]: 'components.progressbar.operation.first_signature',
  [Enum.Operations.Status.INVESTOR_SIGNED]: 'components.progressbar.operation.le_signed',
  [Enum.Operations.Status.INVESTMENT_UPDATED]: 'components.progressbar.operation.closed',
};

export const MAX_STR_LENGTH_TITLE = 20;

// List of status for which the company is forbidden to process its stage 1
export const FORBIDDEN_STATUS_FOR_COMPANY_TO_PROCESS_STAGE1 = [
  OPERATION_STATUSES.ALL_SIGNED,
  OPERATION_STATUSES.PAYMENT_RECEIVED,
  OPERATION_STATUSES.INVESTMENT_UPDATED,
  OPERATION_STATUSES.INVESTMENT_CLOSED,
  OPERATION_STATUSES.CANCELLED,
];

// List of status for which the investor is forbidden to process its operation
export const FORBIDDEN_STATUS_FOR_INVESTOR_TO_PROCESS = [
  OPERATION_STATUSES.INVESTOR_SIGNED,
  OPERATION_STATUSES.ALL_SIGNED,
  OPERATION_STATUSES.PAYMENT_EMITTED,
  OPERATION_STATUSES.PAYMENT_RECEIVED,
  OPERATION_STATUSES.INVESTMENT_UPDATED,
  OPERATION_STATUSES.INVESTMENT_CLOSED,
  OPERATION_STATUSES.CANCELLED,
];

// --------------------------------------------------------------------------------------------
// Objects containing the data needed to display comments
// --------------------------------------------------------------------------------------------

export const PAYMENT_COMMENTS = {
  PAYMENT_TO_BE_MADE: 'Mise en paiement demandée',
  PAYMENT_EMITTED: 'Virement enregistré',
  PAYMENT_RECEIVED: 'La réception du virement a été confirmée',
};

export const FILE_MAX_SIZE_MB = 20;

export const INT_MAX_LIMIT = 2147483647;

export const FUND_COMPANY_TYPE = Enum.CompanyType.FUND_MANAGEMENT;

// --------------------------------------------------------------------------------------------
// List of dashboard statuses
// --------------------------------------------------------------------------------------------
export const DASHBOARD_STATUSES = {
  TRANSMIT: 'TRANSMIT',
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
  OPERATION_SUGGESTED: 'OPERATION_SUGGESTION', // for /api/operation/filters?status={value}
  CANCELLED: 'CANCELLED',
  CORPORATE_ACTIONS: 'CORPORATE_ACTIONS',
  SHAREHOLDING: 'SHAREHOLDING',
  OPERATION_TEMPLATES: 'OPERATION_TEMPLATES',
  MOVEMENT_ORDERS: 'MOVEMENT_ORDERS',
  FUND_MANAGEMENT: 'FUND_MANAGEMENT',
};

export const dashboardTab = {
  TRANSMIT: Enum.Dashboard.Tab.TRANSMIT, // 0
  IN_PROGRESS: Enum.Dashboard.Tab.IN_PROGRESS, // 1
  CLOSED: Enum.Dashboard.Tab.CLOSED, // 2
  OPERATION_SUGGESTED: Enum.Dashboard.Tab.OPERATION_SUGGESTED, // 3
  CANCELLED: Enum.Dashboard.Tab.CANCELLED, // 4
  CORPORATE_ACTIONS: Enum.Dashboard.Tab.CORPORATE_ACTIONS, // 5
  SHAREHOLDING: Enum.Dashboard.Tab.SHAREHOLDING, // 6
  OPERATION_TEMPLATES: Enum.Dashboard.Tab.OPERATION_TEMPLATES, // 7
  MOVEMENT_ORDERS: Enum.Dashboard.Tab.MOVEMENT_ORDERS, // 8
  FUND_MANAGEMENT: Enum.Dashboard.Tab.FUND_MANAGEMENT, // 9
};

export const dashboardTabConstName = {
  [dashboardTab.TRANSMIT]: DASHBOARD_STATUSES.TRANSMIT,
  [dashboardTab.IN_PROGRESS]: DASHBOARD_STATUSES.IN_PROGRESS,
  [dashboardTab.CLOSED]: DASHBOARD_STATUSES.CLOSED,
  [dashboardTab.OPERATION_SUGGESTED]: DASHBOARD_STATUSES.OPERATION_SUGGESTED,
  [dashboardTab.CANCELLED]: DASHBOARD_STATUSES.CANCELLED,
  [dashboardTab.CORPORATE_ACTIONS]: DASHBOARD_STATUSES.CORPORATE_ACTIONS,
  [dashboardTab.SHAREHOLDING]: DASHBOARD_STATUSES.SHAREHOLDING,
  [dashboardTab.OPERATION_TEMPLATES]: DASHBOARD_STATUSES.OPERATION_TEMPLATES,
  [dashboardTab.MOVEMENT_ORDERS]: DASHBOARD_STATUSES.MOVEMENT_ORDERS,
  [dashboardTab.FUND_MANAGEMENT]: DASHBOARD_STATUSES.FUND_MANAGEMENT,
};

export const ACCOUNT_NAVIGATION = {
  PARAMETERS: 'parameters',
  ACCOUNT: 'account',
  LIBRARY: 'library',
};

export const DASHBOARD_RESULT_RANGE = [10, 20, 30, 40, 50];

export const FAQ_URL = {
  BANK: 'https://www.utocat.com/faq-banques',
  COMPANY: 'https://www.utocat.com/faq-entreprises',
  INVESTOR: 'https://www.utocat.com/faq-investisseurs',
};

//Disposal mode
export const FILL_IN_DIRECTLY_MODE = 'fill_in_directly_mode';
export const SEND_TO_INVESTOR_MODE = 'send_to_investor_mode';

// --------------------------------------------------------------------------------------------
// Movement orders constants
// --------------------------------------------------------------------------------------------
export const STATUS_CODE_TO_VALIDATE = [30, 40, 80];
